import React, { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';
import logo from './logo48.png';
import './App.css';
import SignUpScreen from './components/SignUpScreen';
import WeeklySchedule from './components/WeeklySchedule';
import StartMenu from './components/StartMenu';
import WeeklyBookings from './components/WeeklyBookings';
import ManageTeam from './components/ManageTeam';
import TopNav from './components/TopNav';
import { auth, db } from './firebaseConfig';
import { getWeekStart } from './utils';
import UserImportComponent from './components/UserImporter';
import BookingReport from './components/BookingReport';
import LoadingSpinner from './components/LoadingSpinner';

function App() {
  const [user, setUser] = useState(null);
  const [step, setStep] = useState('login');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [hasChildren, setHasChildren] = useState(false);
  const [isLoggingIn , setIsLoggingIn] = useState(true);

  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);


  useEffect(() => {
    //Set selectedDay to the current day index (with Monday being 0):
    if (new Date().getDay() - 1 > 0 && new Date().getDay() - 1 < 5){
      setSelectedDay(new Date().getDay() - 1);
      //if we're currently after noon, set the selectedTime to afternoon:
      if (new Date().getHours() >= 12){
        setSelectedTime("afternoon");
      } else {
        setSelectedTime("morning");
      }
    
    }
   


  }, []);
  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      setIsLoggingIn(true);
      if (currentUser) {
        console.log('User is signed in:', currentUser);
        setIsLoggedIn(true);
        const hasChildrenResult = await checkForChildren(currentUser.uid);
        setHasChildren(hasChildrenResult);
        setStep('');
        setIsLoggingIn(false);
      } else {
        console.log('User is signed out');
        setIsLoggedIn(false);
        setStep('login');
        setHasChildren(false);
        setIsLoggingIn(false);
      }
    });
  
    return () => unsubscribe();
  }, []);

  const checkForChildren = async (userId) => {
    const q = query(collection(db, 'members'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  function signOut() {
    auth.signOut();
  }

  function currentWeek() {
    getWeekStart(new Date());
  }

  function openWeeklyBookings(day, time, weekStart){
    setStep('weeklyBookings');
      setSelectedDay(day);
      setSelectedTime(time);
      setSelectedWeek(weekStart);
  }

  function resetDefaults() {
    setSelectedDay(null);
    setSelectedTime(null);
    setSelectedWeek(null);
  }

  return (
    <div className="App flex flex-col min-h-screen">


      {isLoggingIn ? <div className="flex-grow flex items-center justify-center">
        <div className="text-2xl">
          <LoadingSpinner />
        </div>
        </div>
      :
      !isLoggedIn && !isLoggingIn && <SignUpScreen />}

      {step === "signup" && !isLoggingIn && !isLoggedIn && <SignUpScreen allowSignUp={true}/>}

      {isLoggedIn && !isLoggingIn && (
        <>
          <TopNav
            logo={logo}
            showBackButton={step !== ''}
            onBackClick={() => setStep('')}
            title={step === '' ? 'Herts Coach' : step}
            onLogout={signOut}
            onBookingReport={() => setStep('Booking Report')}
          />
          <div className="flex-grow overflow-auto p-0 ">
            
            {true == false && <>
              {step === '' && hasChildren && (
                <StartMenu
                  onOptionSelected={(selectedStep) => { setStep(selectedStep); resetDefaults();}}
                  name="Welcome"
                  onOpenSlotSelected={openWeeklyBookings}
                  weekStart={"2024-09-01"}
                />
              )}
              {(step === 'manageMembers' || !hasChildren) && (
                <ManageTeam onChildAdded={() => setHasChildren(true)} />
              )}
              {step === 'defaultSchedule' && <WeeklySchedule mode="default" onClose={() => setStep("")} />}
              {step === 'weeklySchedule' && (
                <WeeklySchedule
                  onClose={() => setStep("")}
                  mode="thisweek"
                  subTitle="Adjust the days your child will be on the bus this week."
                />
              )}
            </>}
            {step === "Booking Report"? 
              <BookingReport />
              :
              <WeeklyBookings defaultDay={selectedDay} defaultTime={selectedTime} defaultWeek={selectedWeek}/>
            }
            
          </div>
        </>
      )}

  {true == false &&
      <>
      {true == false && process.env.NODE_ENV === 'development' && <UserImportComponent />}
      <ManageTeam onChildAdded={() => setHasChildren(true)} />
      <WeeklySchedule mode="default" />
      </>
}
    </div>
  );
}

export default App;