import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator, httpsCallable } from 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyBYTE5iUnIk10IyrIFmDPu3flrcZ_iuuoM",
    authDomain: "bookingapp-bb197.firebaseapp.com",
    projectId: "bookingapp-bb197",
    storageBucket: "bookingapp-bb197.appspot.com",
    messagingSenderId: "694240136321",
    appId: "1:694240136321:web:4a875bf867be4be1321180"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

const functions = getFunctions(app);
connectFunctionsEmulator(functions, 'localhost', 5001);
export { functions };