import React from 'react';
import { Typography, Box } from '@mui/material';

const LoginHeader = (props) => (
  <Box sx={{ 
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center', 
    mb: 2,
    mt: 2,
  }}>
    <Box 
      component="img"
      src="/logo192.png"
      alt="Herts Coach"
      sx={{ 
        width: 120, 
        height: 120, 
        mb: 2,
        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'
      }} 
    />
    <Typography 
      variant="h4" 
      component="h1" 
      gutterBottom 
      align="center"
      sx={{ 
        fontWeight: 600,
        color: '#1976d2', // Using Material-UI's primary color
        letterSpacing: '0.5px'
      }}
    >
      Herts Coach
    </Typography>
    <Typography 
      variant="h6" 
      component="h2" 
      align="center"
      sx={{ 
        fontWeight: 400,
        color: 'text.secondary',
        mb: 0
      }}
    >
     {props.allowSignUp ? 'Sign Up' : 'Log In'}
     
    </Typography>
  </Box>
);

export default LoginHeader;