import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Card, 
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  CircularProgress,
  Paper,
  Button
} from '@mui/material';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import DownloadIcon from '@mui/icons-material/Download';

const BookingReport = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userCache, setUserCache] = useState({});
  const [orderBy, setOrderBy] = useState('bookingDate');
  const [order, setOrder] = useState('desc');

  const fetchUserData = async (userId) => {
    if (userCache[userId]) {
      return userCache[userId];
    }

    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUserCache(prev => ({
          ...prev,
          [userId]: userData
        }));
        return userData;
      }
      return null;
    } catch (error) {
      console.error("Error fetching user:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        setLoading(true);
        const guestsQuery = query(
          collection(db, 'guests'),
          where('status', '==', 'Active')
        );
        
        const querySnapshot = await getDocs(guestsQuery);
        const bookingsPromises = querySnapshot.docs.map(async (doc) => {
          const booking = doc.data();
          const userData = await fetchUserData(booking.userId);
          
          return {
            id: doc.id,
            guestName: booking.guestName,
            userId: booking.userId,
            userName: userData?.userName || 'Unknown User',
            bookingDate: booking.createdDate?.toDate() || new Date(),
            weekStart: booking.schedule.weekStart,
            day: booking.schedule.day,
            timeOfDay: booking.schedule.timeOfDay,
            bookingDateFormatted: formatDate(booking.createdDate?.toDate() || new Date()),
            bookedForFormatted: formatBookingDay(
              booking.schedule.weekStart, 
              booking.schedule.day, 
              booking.schedule.timeOfDay
            )
          };
        });

        const bookingsData = await Promise.all(bookingsPromises);
        setBookings(bookingsData);
      } catch (err) {
        console.error("Error fetching bookings:", err);
        setError("Failed to load booking data");
      } finally {
        setLoading(false);
      }
    };

    fetchBookings();
  }, []);

  const formatDate = (date) => {
    const day = date.toLocaleDateString('en-US', { weekday: 'short' });
    const month = date.toLocaleDateString('en-US', { month: 'short' });
    const dayNum = date.getDate();
    const year = date.getFullYear();
    const time = date.toLocaleTimeString('en-US', { 
      hour: '2-digit', 
      minute: '2-digit',
      hour12: true 
    });
    
    return `${day} ${month} ${dayNum} ${year} ${time}`;
  };

  const formatBookingDay = (weekStart, dayIndex, timeOfDay) => {
    const date = new Date(weekStart);
    date.setDate(date.getDate() + parseInt(dayIndex) + 1);
    
    const day = date.toLocaleDateString('en-US', { weekday: 'short' });
    const month = date.toLocaleDateString('en-US', { month: 'short' });
    const dayNum = date.getDate();
    const year = date.getFullYear();
    
    return `${day} ${month} ${dayNum} ${year} (${timeOfDay})`;
  };

  const downloadCSV = () => {
    const headers = ['User', 'Guest Name', 'Seat Taken', 'Date Booked'];
    
    const csvData = getSortedBookings().map(booking => [
      booking.userName,
      booking.guestName,
      booking.bookedForFormatted,
      booking.bookingDateFormatted
    ]);
    
    const csvContent = [
      headers.join(','),
      ...csvData.map(row => row.map(cell => `"${cell}"`).join(','))
    ].join('\n');
    
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `guest_bookings_${formatDate(new Date())}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getSortedBookings = () => {
    const sortedBookings = [...bookings];
    
    sortedBookings.sort((a, b) => {
      let compareA, compareB;
      
      switch (orderBy) {
        case 'userName':
          compareA = a.userName.toLowerCase();
          compareB = b.userName.toLowerCase();
          break;
        case 'guestName':
          compareA = a.guestName.toLowerCase();
          compareB = b.guestName.toLowerCase();
          break;
        case 'bookedFor':
          compareA = new Date(a.weekStart);
          compareB = new Date(b.weekStart);
          break;
        case 'bookingDate':
          compareA = a.bookingDate;
          compareB = b.bookingDate;
          break;
        default:
          compareA = a[orderBy];
          compareB = b[orderBy];
      }

      if (compareA < compareB) return order === 'asc' ? -1 : 1;
      if (compareA > compareB) return order === 'asc' ? 1 : -1;
      return 0;
    });

    return sortedBookings;
  };

  const SortableTableCell = ({ id, label, align }) => (
    <TableCell align={align}>
      <TableSortLabel
        active={orderBy === id}
        direction={orderBy === id ? order : 'asc'}
        onClick={() => handleRequestSort(id)}
      >
        <strong>{label}</strong>
      </TableSortLabel>
    </TableCell>
  );

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'flex-start', 
      p: 3,
      minHeight: '100vh',
      bgcolor: '#f5f5f5'
    }}>
      <Card sx={{ maxWidth: 800, width: '100%' }}>
        <CardContent>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            mb: 2 
          }}>
            <Typography variant="h6" component="h2">
              Guest Bookings
            </Typography>
            <Button 
              variant="outlined" 
              startIcon={<DownloadIcon />}
              onClick={downloadCSV}
              disabled={bookings.length === 0}
            >
              CSV
            </Button>
          </Box>
          
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <SortableTableCell id="userName" label="User" />
                  <SortableTableCell id="guestName" label="Guest Name" />
                  <SortableTableCell id="bookedFor" label="Seat Taken" />
                  <SortableTableCell id="bookingDate" label="Date Booked" />
                </TableRow>
              </TableHead>
              <TableBody>
                {getSortedBookings().map((booking) => (
                  <TableRow key={booking.id}>
                    <TableCell>{booking.userName}</TableCell>
                    <TableCell>{booking.guestName}</TableCell>
                    <TableCell>{booking.bookedForFormatted}</TableCell>
                    <TableCell>{booking.bookingDateFormatted}</TableCell>
                  </TableRow>
                ))}
                {bookings.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No guest bookings found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Box>
  );
};

export default BookingReport;