import React, { useState, useEffect } from 'react';
import { 
  Button, 
  TextField, 
  Card, 
  CardContent, 
  Typography, 
  Box, 
  Alert,
  CircularProgress
} from '@mui/material';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { setDoc, doc, collection, query, where, getDocs } from 'firebase/firestore';
import { auth, db } from '../firebaseConfig';
import LoginHeader from './LoginHeader';

const SignUpScreen = (props) => {
  const [userName, setUserName] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [step, setStep] = useState('phone'); // 'phone', 'code', or 'complete'
  const [loading, setLoading] = useState(false);
  const [userIsNew, setUserIsNew] = useState(false);

  useEffect(() => {
    const setupRecaptcha = () => {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
          'size': 'invisible',
          'callback': (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
          }
        });
      }
    };

    setupRecaptcha();

    return () => {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = null;
      }
    };
  }, []);

  const validateAndFormatPhoneNumber = (phone) => {
    // Remove any whitespace or special characters except for the leading '+'
    const cleanedNumber = phone.replace(/\s+/g, '').replace(/[-()+]/g, (match) => match === '+' ? '+' : '');
    
    // Check if the number starts with a '+'
    if (!cleanedNumber.startsWith('+')) {
      //if it starts with 0, add the country code automatically, default to UK:
      if (cleanedNumber.startsWith('0')) {
        return { isValid: true, formattedNumber: '+44' + cleanedNumber.slice(1) };
      }

      return { isValid: false, formattedNumber: null };
    }
    
    // Simple regex to check if the number contains only digits after the '+'
    const phonePattern = /^\+\d{6,15}$/;
    
    if (phonePattern.test(cleanedNumber)) {
      return { isValid: true, formattedNumber: cleanedNumber };
    } else {
      return { isValid: false, formattedNumber: null };
    }
  };

  const handleSendCode = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
  
    const { isValid, formattedNumber } = validateAndFormatPhoneNumber(userPhone);
    if (!isValid) {
      setError('Please enter a valid phone number with country code (e.g., +441234567890)');
      setLoading(false);
      return;
    }
  
    try {
      const recaptchaVerifier = window.recaptchaVerifier;
      if (!recaptchaVerifier) {
        throw new Error('RecaptchaVerifier not initialized');
      }

     
      const confirmationResult = await signInWithPhoneNumber(auth, formattedNumber, recaptchaVerifier);
      setConfirmationResult(confirmationResult);
      setUserPhone(formattedNumber); // Update the state with the formatted number
      
      console.log('Confirmation result:', confirmationResult, auth);

      
      
       //look up user in users collection database by phoneNumber field - if it does not exist, then it is a new user.
       //users where phoneNumber = formattedNumber:

      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('userPhone', '==', formattedNumber));
      const querySnapshot = await getDocs(q);
      const isUserNew = querySnapshot.empty;
     
      

      console.log("isUserNew", isUserNew);


      if (props.allowSignUp && isUserNew) {
        setStep('code');
        setUserIsNew(true);
      }
      else {
        if (!isUserNew) {
          setStep('code');
          setUserIsNew(false);
        }
        else{
          setError('To register as a new user, please contact the administrator.');
          setUserIsNew(true);
        }
      }
      
      

    } catch (error) {
      console.error('Error sending verification code:', error);
      setError(`Failed to send verification code: ${error.message}`);
      // Reset reCAPTCHA if there's an error
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.render().then(widgetId => {
          window.grecaptcha.reset(widgetId);
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    if (!confirmationResult) {
      setError('Please request a new verification code.');
      setLoading(false);
      return;
    }

    try {
      const credential = await confirmationResult.confirm(verificationCode);
      const user = credential.user;

      if (userIsNew && userName && userPhone) {
      // Store additional user data in Firestore
        await setDoc(doc(db, 'users', user.uid), {
          userName,
          userPhone,
        });
      }

      setSuccess('Sign up successful!');
      setStep('complete');
    } catch (error) {
      console.error('Error verifying code:', error);
      setError('Failed to verify code. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      justifyContent: 'center', 
      alignItems: 'center', 
      flexGrow: 1,
      width: '100%',
      height: '100%',
      bgcolor: '#f0f0f0',
      overflow: 'auto',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    }}
    
    >
      <Card sx={{ 
        maxWidth: 380, 
        width: 'calc(100% - 32px)', 
        m: 2,
        maxHeight: 'calc(100% - 32px)',
        overflowY: 'auto'
       }}>
        <CardContent>
          <LoginHeader {...props}/>
          {step === 'phone' && (
            <form onSubmit={handleSendCode}>

              {props.allowSignUp && 
                <TextField
                  fullWidth
                  margin="normal"
                  label="Parent's name"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  required
                />
              }
              <TextField
                fullWidth
                margin="normal"
                label="Parent's mobile phone number"
                value={userPhone}
                onChange={(e) => setUserPhone(e.target.value)}
                required
                placeholder="E.g. +441234567890"
              />
              {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
              <Button 
                type="submit" 
                variant="contained" 
                color="primary" 
                fullWidth 
                sx={{ mt: 2, mb: 1, py: 2, fontSize: '1rem' }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Send Verification Code'}
              </Button>
            </form>
          )}
          {step === 'code' && (
            <form onSubmit={handleVerifyCode}>
              <TextField
                fullWidth
                margin="normal"
                label="Verification Code"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                required
              />
              {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
              <Button 
                type="submit" 
                variant="contained" 
                color="primary" 
                fullWidth 
                sx={{ mt: 2, mb: 1, py: 2, fontSize: '1rem' }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Verify Code'}
              </Button>
            </form>
          )}
          {step === 'complete' && (
            <Alert severity="success" sx={{ mt: 2 }}>{success}</Alert>
          )}
          
          <div id="recaptcha-container"></div>
        </CardContent>
      </Card>
    </Box>
  );
};

export default SignUpScreen;