import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Paper
} from '@mui/material';
import { collection, addDoc, serverTimestamp, getDoc, doc} from 'firebase/firestore';
import { auth, db } from '../firebaseConfig'; // Adjust this import path as needed

const BookGuest = ({ open, onClose, weekStart, schedule, onBookingMade }) => {
  const [guestName, setGuestName] = useState('');

  const handleNameChange = (event) => {
    setGuestName(event.target.value);
  };

  const formatDateForDisplay = (dateString) => {
    


    
    //get the first monday after this date:
    const date = new Date(dateString);
    const day = date.getDay();
    const diff = date.getDate() + 1;
    const monday = new Date(date.setDate(diff));

    //adjust the date to as follows:
    //get the weekday name using schedule.day and the morning or afternoon using schedule.timeOfDay:
    //return the date in the format: "Mon, 1 Jan 2022"

    switch(schedule.day){
      case 0:
        monday.setDate(monday.getDate());
        break;
      case 1:
        monday.setDate(monday.getDate() + 1);
        break;
      case 2:
        monday.setDate(monday.getDate() + 2);
        break;
      case 3:
        monday.setDate(monday.getDate() + 3);
        break;
      case 4:
        monday.setDate(monday.getDate() + 4);
        break;
    }

    return monday.toLocaleDateString('en-GB', { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' });

  };

    const onSuccessfullyBooked = () => {
        if (onBookingMade) {
            onBookingMade();
        }
    };

  const handleReserve = async () => {
    if (!guestName.trim()) {
      alert('Please enter a passenger name');
      return;
    }

    try {
      const user = auth.currentUser;
      if (!user) {
        alert('You must be signed in to make a reservation');
        return;
      }

      const userDoc = await getDoc(doc(db, 'users', user.uid));
      var currentUserInfo = userDoc.data();

      await addDoc(collection(db, 'guests'), {
        userId: user.uid,
        guestName: guestName.trim(),
        status: 'Active',
        createdDate: serverTimestamp(),
        bookingNote: currentUserInfo?.userName? "Booked by " + currentUserInfo.userName : "",
        schedule: {
          weekStart,
          ...schedule
        }
      });

      onSuccessfullyBooked();
      setGuestName('');
      onClose();
    } catch (error) {
      console.error('Error making reservation:', error);
      alert('Failed to make reservation. Please try again.');
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="book-guest-modal"
      aria-describedby="book-guest-description"
    >
        
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          {formatDateForDisplay(weekStart, schedule)}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Reserve a guest seat for the <strong>{schedule.timeOfDay}</strong>
        </Typography>
        <TextField
          fullWidth
          label="Name of guest passenger"
          variant="outlined"
          value={guestName}
          onChange={handleNameChange}
          margin="normal"
        />
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleReserve}>
            Reserve
          </Button>
          
        </Box>
      </Paper>
    </Modal>
  );
};

export default BookGuest;